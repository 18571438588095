import React, { useState } from "react";
import Header from "../../Components/shared/header/Header";
import Footer from "../../Components/shared/footer/Footer";
import { useNavigate } from "react-router-dom";
import apiClient from "../../api/api";
import { toast, ToastContainer } from "react-toastify";
import OtpInput from "react-otp-input";
import "react-toastify/dist/ReactToastify.css";

export default function EmailVerification() {
  const [verification_token, setVerification_token] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleVerificationTokenChange = (verification_token) => {
    setVerification_token(verification_token);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (verification_token.length !== 6) {
      toast.error("Please enter a 6-digit Token sent to your email");
      return;
    }

    setIsLoading(true);
    apiClient
      .post("/verify-email", { verification_token })
      .then((response) => {
        setIsLoading(false);
        if (response.data.message === "Email verified successfully") {
          toast.success("Email verified successfully");
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response && error.response.data) {
          toast.error(error.response.data.message);
        } else {
          console.error(error);
          toast.error("An error occurred. Please try again.");
        }
      });
  };

  return (
    <div className="app">
      <Header />
      <ToastContainer />
      <div className="section">
        <div className="login">
          <h1>Email Verification</h1>
          <p className=" font-normal text-base py-2">
            Enter verification code sent to ****@gmail.com
          </p>
          <div className="form">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                {/* <label htmlFor="otp" className="form-label">
                  Enter OTP
                </label> */}
                <OtpInput
                  value={verification_token}
                  onChange={handleVerificationTokenChange}
                  numInputs={6}
                  isInputNum
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} />}
                  inputStyle={{
                    width: "2rem",
                    height: "2rem",
                    margin: "0 0.5rem",
                    fontSize: "1.5rem",
                    borderRadius: 4,
                    border: "1px solid rgba(0,0,0,0.3)",
                  }}
                  focusStyle={{
                    border: "1px solid #000",
                  }}
                />
              </div>
              <div className="form-group d-flex justify-content-end">
                <button type="submit" disabled={isLoading}>
                  {isLoading ? "Verifying..." : "Verify"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
