import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../../Context";
import { RxAvatar } from "react-icons/rx";

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropOpen, setDropOpen] = useState(false);
  const { isAuthenticated, checkAuthStatus } = useAuth();
  const profile_image = localStorage.getItem("profile_image");
  const currentLocation = useLocation();

  const trackerVisiblePaths = [
    "/services",
    "/office-quote",
    "/local-quote",
    "/interstate-quote",
    "/office",
    "/local",
    "/interstate",
    "/track",
  ];

  useEffect(() => {
    checkAuthStatus();
  }, []);

  return (
    <header id="header" className="fixed-top bg-white py-4 ">
      <div className="container d-flex align-items-center lg:px-[70px] 2xl:px-[100px]">
        <Link to="/" className="me-auto flex items-center">
          <img src="/Logo.svg" alt="Logo" className="" />
        </Link>
        {isAuthenticated ? (
          <Link
            className=" lg:hidden items-center justify-center self-center pr-5"
            to="/profile"
          >
            {profile_image.length > 4 ? (
              <img
                src={profile_image}
                alt="user profile image"
                className="w-[50px] h-[50px] object-cover object-top rounded-full items-center self-center"
              />
            ) : (
              <RxAvatar className="w-[45px] h-[45px]" />
              // <img
              //   src={"/avatar.svg"}
              //   alt="user profile image"
              //   className="w-[50px] h-[50px] object-cover object-top rounded-full items-center self-center"
              // />
            )}
          </Link>
        ) : (
          ""
        )}

        <nav
          id="navbar"
          className={"navbar " + (menuOpen ? "navbar-mobile" : "")}
        >
          <ul>
            <li style={{ paddingTop: "4px" }}>
              <Link to="/" className="nav-link scrollto active">
                HOME
              </Link>
            </li>
            <li style={{ paddingTop: "4px" }}>
              <Link to="/about" className="nav-link scrollto active">
                ABOUT US
              </Link>
            </li>
            <li className="dropdown">
              <Link to="#" className="nav-link scrollto active">
                <span>SERVICES</span>
                <i
                  className="bi bi-chevron-down"
                  onClick={() => setDropOpen(!dropOpen)}
                ></i>
              </Link>
              <ul className={dropOpen ? "dropdown-active" : ""}>
                <li>
                  <Link to="/delivery">Delivery</Link>
                </li>
                <li>
                  <Link to="/relocation">Relocation</Link>
                </li>
                {/* <li>
                  <Link to="/local">Local Move</Link>
                </li> */}
                {/* <li>
                  <Link to="/office">Office Move</Link>
                </li> */}
              </ul>
            </li>
            <li className="dropdown">
              <Link to="/partner" className="nav-link scrollto active">
                <span>PARTNER</span>
                <i
                  className="bi bi-chevron-down"
                  onClick={() => setDropOpen(!dropOpen)}
                ></i>
              </Link>
              <ul className={dropOpen ? "dropdown-active" : ""}>
                <li>
                  <Link to="/pack">Become a Parker</Link>
                </li>
                <li>
                  <Link to="/move">Move for us</Link>
                </li>
              </ul>
            </li>
            {!isAuthenticated ? (
              <>
                <li>
                  <Link to="/login" className="getstarted">
                    Log In
                  </Link>
                </li>
                <li>
                  <Link to="/signup" className="nav-link">
                    Sign up
                  </Link>
                </li>
              </>
            ) : (
              <>
                {trackerVisiblePaths.includes(currentLocation.pathname) && (
                  <li>
                    <Link to="/track" className="nav-link">
                      TRACKER
                    </Link>
                  </li>
                )}
                <li className="hidden lg:block">
                  <Link
                    className=" items-center justify-center self-center"
                    to="/profile"
                  >
                    {profile_image.length > 4 ? (
                      <img
                        src={profile_image}
                        alt="user profile image"
                        className="w-[60px] h-[60px] object-cover rounded-full items-center self-center"
                      />
                    ) : (
                      // <img
                      //   src={"/avatar.svg"}
                      //   alt="user profile image"
                      //   className="w-[50px] h-[50px] object-cover object-top rounded-full items-center self-center"
                      // />
                      <RxAvatar className="w-[45px] h-[45px]" />
                    )}
                  </Link>
                </li>
              </>
            )}
          </ul>
          <i
            className={`bi mobile-nav-toggle ${menuOpen ? "bi-x" : "bi-list"}`}
            onClick={() => setMenuOpen(!menuOpen)}
          ></i>
        </nav>
      </div>
    </header>
  );
}
