import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
} from "@headlessui/react"; // Headless UI Modal
import apiClient from "../../api/api";
import Header from "../../Components/shared/header/Header";
import Footer from "../../Components/shared/footer/Footer";
import * as Components from "../../Components";

export const BulkDelivery = () => {
  const [formData, setFormData] = useState({
    pickup_address: "",
    sender_fullname: "",
    sender_number: "",
    pickup_date: "",
  });

  const [additionalDeliveries, setAdditionalDeliveries] = useState([]); // To store multiple deliveries
  const [currentDelivery, setCurrentDelivery] = useState({
    address: "",
    name: "",
    phone: "",
    date: "",
  }); // Modal form state
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [editIndex, setEditIndex] = useState(null); // Track editing index
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleModalChange = (e) => {
    const { id, value } = e.target;
    setCurrentDelivery((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleAddDelivery = () => {
    if (editIndex !== null) {
      // Editing an existing delivery
      const updatedDeliveries = [...additionalDeliveries];
      updatedDeliveries[editIndex] = currentDelivery;
      setAdditionalDeliveries(updatedDeliveries);
      setEditIndex(null); // Reset editing index
    } else {
      // Adding a new delivery
      setAdditionalDeliveries((prev) => [...prev, currentDelivery]);
    }

    setCurrentDelivery({
      address: "",
      name: "",
      phone: "",
      date: "",
    }); // Reset modal form
    setIsModalOpen(false); // Close modal
  };

  const handleEdit = (index) => {
    setCurrentDelivery(additionalDeliveries[index]); // Load data into modal
    setEditIndex(index); // Set index for editing
    setIsModalOpen(true); // Open modal
  };

  const handleDelete = (index) => {
    setAdditionalDeliveries((prev) => prev.filter((_, i) => i !== index)); // Remove from list
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const authToken = localStorage.getItem("authToken");

    const finalData = {
      ...formData,
      receiver_details: additionalDeliveries, // Include all additional deliveries in submission
    };

    try {
      const response = await apiClient.post("users/bulk/delivery", finalData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      toast.success(" Bulk Delivery created successfully");
      setTimeout(() => {
        setIsLoading(false);
        navigate("/thank-you");
      }, 3000);
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data) {
        const errorData = error.response.data;
        Object.keys(errorData).forEach((key) => {
          errorData[key].forEach((message) => {
            toast.error(message);
          });
        });
      } else {
        console.error(error);
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  return (
    <>
      <Header />
      <section className="container  mt-40 lg:px-[70px]  2xl:px-[100px]">
        <ToastContainer />
        <h2 className=" font-bold text-base pb-1 pt-2 text-[#000000]">
          Plan Your Delivery
        </h2>
        <p>Schedule your delivery for the perfect time that fits your needs.</p>

        {/* Pickup Address Form */}
        <Form className="second mt-5" onSubmit={handleSubmit}>
          <div className="mb-3">
            <label
              htmlFor="pickup_address"
              className="block mb-2 text-md font-medium text-[#212120]"
            >
              Pick Up Address
            </label>
            <input
              type="text"
              id="pickup_address"
              className="bg-white border !border-[#696969] text-gray-900 text-sm rounded block w-full  p-4"
              placeholder="Full address"
              value={formData.pickup_address}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="sender_fullname"
              className="block mb-2 text-md font-medium text-[#212120]"
            >
              Sender’s Full name
            </label>
            <input
              type="text"
              id="sender_fullname"
              className="bg-white border !border-[#696969] text-gray-900 text-sm rounded block w-full  p-4"
              placeholder="Full Name"
              value={formData.sender_fullname}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="sender_number"
              className="block mb-2 text-md font-medium text-[#212120]"
            >
              Sender’s Number
            </label>
            <input
              type="tel"
              id="sender_number"
              className="bg-white border !border-[#696969] text-gray-900 text-sm rounded block w-full  p-4"
              placeholder="Phone Number"
              value={formData.sender_number}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="pickup_date"
              className="block mb-2 text-md font-medium text-[#212120]"
            >
              Pick Up Date
            </label>
            <input
              type="date"
              id="pickup_date"
              className="bg-white border !border-[#696969] text-gray-900 text-sm rounded block w-full  p-4"
              placeholder="Pickup Date"
              value={formData.pickup_date}
              onChange={handleChange}
              required
            />
          </div>
          {/* Display added delivery addresses */}
          {additionalDeliveries.length > 0 && (
            <div className="mt-6">
              <ul className="flex gap-3 flex-col sm:flex-row flex-wrap">
                {additionalDeliveries.map((delivery, index) => (
                  <li
                    key={index}
                    className="mb-4 flex gap-12 border !border-[#A27003] rounded-2xl max-w-[400px] px-5 py-3 "
                  >
                    <div className="space-y-2">
                      <h3 className="font-bold text-lg">Receiver's Details</h3>{" "}
                      <p>
                        <strong>Address:</strong> {delivery.address}
                      </p>
                      <p>
                        <strong>Name:</strong> {delivery.name}
                      </p>
                      <p>
                        <strong>Phone Number:</strong> {delivery.phone}
                      </p>
                      <p>
                        <strong>Delivery Date:</strong> {delivery.date}
                      </p>
                    </div>

                    <div className="flex flex-col gap-4 justify-end">
                      {" "}
                      <div
                        onClick={() => handleEdit(index)}
                        className="mr-2 cursor-pointer"
                      >
                        <svg
                          width="19"
                          height="20"
                          viewBox="0 0 19 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.914 14.0001L14.056 3.85808L12.642 2.44408L2.5 12.5861V14.0001H3.914ZM4.743 16.0001H0.5V11.7571L11.935 0.322083C12.1225 0.134612 12.3768 0.0292969 12.642 0.0292969C12.9072 0.0292969 13.1615 0.134612 13.349 0.322083L16.178 3.15108C16.3655 3.33861 16.4708 3.59292 16.4708 3.85808C16.4708 4.12325 16.3655 4.37756 16.178 4.56508L4.743 16.0001ZM0.5 18.0001H18.5V20.0001H0.5V18.0001Z"
                            fill="#A27003"
                          />
                        </svg>
                      </div>
                      <div
                        onClick={() => handleDelete(index)}
                        className="text-red-500 cursor-pointer"
                      >
                        <svg
                          width="18"
                          height="22"
                          viewBox="0 0 18 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M6.55457 4.37875L0.538574 3.31875L0.712574 2.33275C0.724039 2.26806 0.748139 2.20626 0.783498 2.15089C0.818857 2.09552 0.864781 2.04766 0.918646 2.01005C0.97251 1.97244 1.03326 1.94581 1.09742 1.93169C1.16158 1.91757 1.2279 1.91623 1.29257 1.92775L15.1916 4.37875H15.2796H15.1926L17.0476 4.70575C17.1124 4.7171 17.1743 4.74111 17.2297 4.77642C17.2852 4.81173 17.3332 4.85764 17.3709 4.91151C17.4086 4.96539 17.4353 5.02618 17.4495 5.09039C17.4637 5.15461 17.4651 5.221 17.4536 5.28575L17.2796 6.26975L15.1896 5.90175L14.3896 19.4957C14.3598 20.0046 14.1368 20.4829 13.7662 20.8329C13.3956 21.1828 12.9053 21.3781 12.3956 21.3787H5.16657C4.65632 21.3789 4.16532 21.1839 3.79408 20.8339C3.42284 20.4838 3.19942 20.0051 3.16957 19.4957L2.37057 5.87875H15.0606L6.55457 4.37875ZM5.78057 8.37875L6.28057 17.3787H7.78057L7.38057 8.37875H5.78057ZM10.2806 8.37875L9.78057 17.3787H11.2806L11.7806 8.37875H10.2806ZM7.63457 0.507746L11.5746 1.20175C11.6393 1.21321 11.7011 1.23731 11.7564 1.27267C11.8118 1.30803 11.8597 1.35395 11.8973 1.40782C11.9349 1.46168 11.9615 1.52243 11.9756 1.58659C11.9898 1.65075 11.9911 1.71707 11.9796 1.78175L11.8056 2.76575L6.88157 1.89775L7.05557 0.912746C7.06704 0.848058 7.09114 0.786263 7.1265 0.730893C7.16186 0.675523 7.20778 0.627664 7.26165 0.590052C7.31551 0.55244 7.37626 0.525812 7.44042 0.511689C7.50458 0.497567 7.5699 0.496227 7.63457 0.507746Z"
                            fill="#A27003"
                          />
                        </svg>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="flex justify-end ">
            {" "}
            <Button
              onClick={() => setIsModalOpen(true)}
              className="mt-4 !bg-[#343432] rounded-[4px] text-sm p-3 hover:!bg-gray-700 !border-none"
            >
              Add Receiver’s Details
            </Button>
          </div>

          <div className="mb-6">
            <label
              htmlFor="description"
              className="block mb-2 text-md font-medium text-[#212120]"
            >
              Additional information
            </label>
            <textarea
              id="description"
              rows={6}
              className="bg-white border !border-[#696969] text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-4"
              placeholder="Kindly enter your package description"
              value={formData.description}
              onChange={handleChange}
              required
            ></textarea>
          </div>

          {additionalDeliveries.length > 0 && (
            <div className="flex justify-end">
              {" "}
              <Button
                className="mt-4 text-base px-4  !bg-[#F3B734] border-none text-black font-bold !py-3"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Booking..." : "Submit"}
              </Button>
            </div>
          )}
        </Form>

        {/* Modal for adding/editing delivery address */}
        <Dialog
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          className="fixed z-50 inset-0 overflow-y-auto"
        >
          <DialogBackdrop className="fixed inset-0 !bg-black/70" />
          <div className="fixed inset-0 flex w-screen items-center justify-center p-4 lg:mt-20">
            <DialogPanel className="max-w-lg space-y-4 border bg-white p-4 ">
              <Form className="sm:w-[340px] sm:pt-5 lg:w-[450px]">
                <div className="mt-3">
                  <label
                    htmlFor="address"
                    className="block mb-2 text-md font-medium text-[#212120]"
                  >
                    Delivery Address
                  </label>
                  <input
                    type="text"
                    id="address"
                    className="bg-white border !border-[#696969] text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-4"
                    placeholder="Full address"
                    value={currentDelivery.address}
                    onChange={handleModalChange}
                    required
                  />
                </div>

                {/* Add more fields for receiver info */}
                <div className="mt-3">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-md font-medium text-[#212120]"
                  >
                    Receiver’s Full Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="bg-white border !border-[#696969] text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-4"
                    placeholder="Full name"
                    value={currentDelivery.name}
                    onChange={handleModalChange}
                    required
                  />
                </div>

                <div className="mt-3">
                  <label
                    htmlFor="phone"
                    className="block mb-2 text-md font-medium text-[#212120]"
                  >
                    Receiver’s Number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    className="bg-white border !border-[#696969] text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-4"
                    placeholder="Phone number"
                    value={currentDelivery.phone}
                    onChange={handleModalChange}
                    required
                  />
                </div>
                <div className="mt-3">
                  <label
                    htmlFor="date"
                    className="block mb-2 text-md font-medium text-[#212120]"
                  >
                    Delivery Date
                  </label>
                  <input
                    type="date"
                    id="date"
                    className="bg-white border !border-[#696969] text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-4"
                    placeholder="Phone number"
                    value={currentDelivery.date}
                    onChange={handleModalChange}
                    required
                  />
                </div>
                <div className="flex justify-end">
                  {" "}
                  <Button
                    onClick={handleAddDelivery}
                    className="mt-4 text-base px-4 py-2.5 !bg-[#F3B734] border-none text-black font-bold"
                  >
                    {editIndex !== null ? "Save" : "Save"}
                  </Button>
                </div>
              </Form>
            </DialogPanel>
          </div>
        </Dialog>
        <Components.StickyBar />
      </section>

      <Footer />
    </>
  );
};

