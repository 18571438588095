import React from "react";
import { Link } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { IoLogoWhatsapp } from "react-icons/io";

export default function Footer() {
  return (
    <footer className="w-full text-[#F5F5F5] mt-[40px]">
      <div className="bg-[#5E5A51] sm:p-5 lg:px-[150.47px] lg:py-12 2xl:pl-[300px]">
        <div className="">
          <div className="grid md:grid-cols-3 md:gap-2 ">
            <div className="lg:max-w-[322px]">
              <div className="">
                <h3 className="sm:text-xl lg:text-3xl border-b-4 pb-4 text-left">
                  About Us
                </h3>
                <p className="sm:text-sm lg:text-base py-6 text-left lg:text-justify">
                  We are specialized in providing seamless conveyance of items,
                  also ensuring a stress-free moving experience for our clients
                </p>
              </div>
              <div className="text-left flex flex-col space-y-1">
                {" "}
                <p className="flex gap-2 items-center">
                  <strong>
                    <i class="bi bi-envelope"></i>
                  </strong>{" "}
                  <a href="mailto:logistics@jvtng.com">Logistics@jvtng.com</a>
                </p>
                <p className="flex gap-2 items-center">
                  <strong>
                    <i class="bi bi-telephone-fill"></i>
                  </strong>{" "}
                  <a href="tel:+2349067970283">09067970283</a>
                </p>
                <p className="flex gap-2 items-center">
                  <strong>
                    <IoLogoWhatsapp style={{ color: "	#25D366" }} />
                  </strong>{" "}
                  09067970283
                </p>
              </div>
            </div>
            <div className="lg:max-w-[322px]">
              <h3 className=" sm:text-xl lg:text-3xl border-b-4 pb-4 text-left">
                Our Services
              </h3>

              <ul className="text-left lg:text-base sm:text-sm flex flex-col space-y-2 py-6">
                <li>
                  <i class="bx bx-chevron-right"></i>{" "}
                  <Link to="/delivery" className="text-lg">
                    Delivery
                  </Link>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>{" "}
                  <Link to="/relocation" className="text-lg">
                    Relocation
                  </Link>
                </li>
              </ul>

              <div className="sm:mb-5 lg:mt-20">
                <div className="flex gap-3">
                  {/* <a href="#" className="x" style={{ color: "black" }}>
                    <i class="bi bi-twitter-x"></i>
                  </a> */}
                  <a
                    href="https://www.facebook.com/profile.php?id=61565177758364"
                    target="_blank"
                    className=""
                    style={{ color: "#3b5998" }}
                  >
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/jvt_logistics?igsh=amYxemprYm4yN2tk"
                    target="_blank"
                    className=""
                    style={{ color: "#E1306C" }}
                  >
                    <i className="bi bi-instagram"></i>
                  </a>

                  <a
                    href="https://www.linkedin.com/company/jvt-enterprise-limited/"
                    target="_blank"
                    className=""
                    style={{ color: "#0A66C2" }}
                  >
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="lg:max-w-[322px]">
              <h3 className="sm:text-xl lg:text-3xl border-b-4 pb-4 text-left">
                Subscribe
              </h3>

              <p className="py-6 lg:text-base sm:text-sm text-left lg:text-justify">
                Join our community. Sign up to receive our newsletter for the
                latest updates, special offers, and expert moving advice,
                straight to your inbox. Don't miss out – subscribe now!
              </p>
              <form action="" method="post" className="flex flex-col gap-4">
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  className="p-3 bg-transparent border border-white rounded-md"
                />
                <input
                  type="submit"
                  value="Subscribe"
                  className="bg-[#F3B734] p-3 text-[#212120] rounded-md"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#A27003] sm:px-2 py-2.5 lg:px-[150.47px] lg:py-5 flex justify-between 2xl:pl-[300px]">
        <div class="flex gap-4">
          <p className="sm:text-xs  lg:text-base">© 2024 JVT Logistics</p>
        </div>
        <div class="flex gap-4 sm:text-xs lg:text-base">
          <Link to="/terms_and_conditions">
            <span className="underline underline-offset-4 decoration-[#F3B734]">
              TERMS
            </span>{" "}
            & CONDITION
          </Link>
          <Link to="/faq">
            <span className="underline underline-offset-4 decoration-[#F3B734]">
              FA
            </span>
            QS
          </Link>
        </div>
      </div>
    </footer>
  );
}
